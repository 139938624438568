// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_inner {
    & {
        padding-bottom: remify(($content-padding * (50 / $content-padding)));
        padding-top: remify(($content-padding * (50 / $content-padding)));
    }
}

.content_row {
    .hero-block.-tall + .content-block > .content_inner > .content_post > &:first-child {
        margin-top: remify(($content-padding * (-75 / $content-padding)));
    }
}

.content_sidebar {
    & {
        width: remify(330, 16);
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        padding-bottom: remify(($content-padding * (50 / $content-padding)));
        padding-top: remify(($content-padding * (50 / $content-padding)));
    }

    > .content_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
