// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

// base

.footer_row {
    .col[data-order-mobile="1"],
    [class^="col-"][data-order-mobile="1"],
    .col[data-order-mobile="2"],
    [class^="col-"][data-order-mobile="2"],
    .col[data-order-mobile="3"],
    [class^="col-"][data-order-mobile="3"],
    .col[data-order-mobile="4"],
    [class^="col-"][data-order-mobile="4"] {
        order: 0;
    }
}

.footer_text {
    & {
        font-size: remify(16, 16);
        opacity: 0.33;
    }
}
