// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Ninja Forms
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

/* ------------------------------------------------------------------------ *\
 * Opinionated Tweaks
\* ------------------------------------------------------------------------ */

// list field fix

.nf-field-container {
    .list-checkbox .input.-checkbox,
    .list-radio .input.-radiobutton {
        top: remify(4, 16);
    }

    .list-checkbox .text.-label,
    .list-radio .text.-label {
        padding-left: remify(24, 16);
    }
}

// touch list field fix

@media (pointer: coarse) {
    .nf-field-container {
        .list-checkbox .input.-checkbox,
        .list-radio .input.-radiobutton {
            top: 0;
        }

        .list-checkbox_item .text.-label,
        .list-radio_item .text.-label {
            padding-left: remify(32, 16);
        }
    }
}
