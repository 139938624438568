// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget_title {
    & {
        font-size: remify(20, 16);
    }

    &.-alt {
        font-size: remify(21, 16);
    }
}
