// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tiled Gallery without Jetpack
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-no-qualifying-type, selector-no-type, selector-class-pattern */

.gallery {
    & {
        display: flex;
        flex-wrap: wrap;
    }
}

.gallery-item {
    & {
        justify-content: space-around;
    }

    img {
        margin: 0 auto;
    }
}

.gallery-columns-1 {
    .gallery-item {
        flex: 1 0 100%;
    }
}

.gallery-columns-2 {
    .gallery-item {
        flex: 1 0 50%;
    }
}

.gallery-columns-3 {
    .gallery-item {
        flex: 1 0 33%;
    }
}

.gallery-columns-4 {
    .gallery-item {
        flex: 1 0 25%;
    }
}
