// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero_swiper-container {
    .hero-block.-tall & .swiper-image {
        height: remify(1200, 16);
    }
}
