// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Custom

.swiper-caption {
    & {
        padding-bottom: remify(($content-padding * (35 / $content-padding)));
        padding-top: remify(($content-padding * (35 / $content-padding)));
    }
}

.swiper-title {
    & {
        font-size: remify(44, 16);
    }
}
