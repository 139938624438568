// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * CTA Button
\* ------------------------------------------------------------------------ */

/* alt variant */

.cta-button.-alt {
    & {
        display: block;
        text-align: center;
    }

    .cta-button_icon {
        display: block;
        margin: remify(0 auto 10, 64);
    }

    .cta-button_icon .icon_svg {
        bottom: auto;
        position: static;
    }
}
