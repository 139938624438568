// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: $xl-break) {
        font-size: (16 / (strip-unit($xl-break) * 16)) * 100vw;
    }
}

.header_inner {
    & {
        padding-bottom: remify(($content-padding * (25 / $content-padding)));
        padding-top: remify(($content-padding * (25 / $content-padding)));
    }
}

.header_logo {
    & {
        padding-bottom: 0;
        padding-top: 0;
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        padding-bottom: remify(($content-padding * (25 / $content-padding)));
        padding-top: remify(($content-padding * (25 / $content-padding)));
    }

    > .header_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}

/* overlay variant */

.header-block.-overlay {
    & + * {
        margin-top: 0;
    }
}
